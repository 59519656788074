import React from "react";
import { technologies } from "../../constants/constants";

import "./skills.css";

const Skills = () => {
  return (
    <div className="portfolio__skills section__margin">
      <div className="portfolio__skills-header">
        <h1>Skills</h1>
        <h3>Tools & technologies I work with:</h3>
      </div>
      <div className="portfolio__skills-content">
        <div className="portfolio__skills_technologies-container">
          {technologies.map((tech, i) => (
            <div className="portfolio__skills_technology-box" key={i}>
              <img src={tech.src} alt={tech.name} />
              <p className="portfolio__skills_technology-names">{tech.name}</p>
            </div>
          ))}
        </div>

        <div className="portfolio__skills-experience"></div>
      </div>
    </div>
  );
};

export default Skills;
