import React from "react";
import { socialMedia } from "../../constants/constants";

import "./footer.css";

const footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="portfolio__footer section__margin">
      <div className="portfolio__footer-socials">
        {socialMedia.map((social) => (
          <a
            key={social.id}
            href={social.link}
            target="_blank"
            rel="noopener noreferrer"
            className="portfolio__footer_social-icon"
          >
            {social.icon}
          </a>
        ))}
      </div>

      <div className="portfolio__footer-text">
        <p>Copyright &copy; Victor Muya {`${currentYear}`}</p>
      </div>
    </div>
  );
};

export default footer;
