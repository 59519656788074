import React from 'react';
import { Hero, Skills, Work, Contact, Footer } from './containers';

import './App.css';

function App() {
  return (
    <div className="App">
      <Hero />
      <Skills />
      <Work />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
