import Html from "../assets/html5.png";
import Css from "../assets/css.png";
import JavaScript from "../assets/js.png";
import ReactLogo from "../assets/react.png";
import Sass from "../assets/sass.png";
import Figma from "../assets/figma.png";
import Git from "../assets/git.png";
import Jira from "../assets/jira.png";
import Nextjs from "../assets/nextjs.svg";

import Archis from "../assets/archis.png";
import Modern from "../assets/gpt3.png";
import Endurance from "../assets/endurance.png";

import { BsGithub, BsLinkedin, BsTwitter } from "react-icons/bs";

export const socialMedia = [
  {
    id: 1,
    icon: <BsGithub />,
    link: "https://github.com/vmuya",
  },
  {
    id: 2,
    icon: <BsLinkedin />,
    link: "https://www.linkedin.com/in/victormuya/",
  },
  {
    id: 3,
    icon: <BsTwitter />,
    link: "https://twitter.com/muyavictor_",
  },
];

export const technologies = [
  {
    src: Html,
    name: "HTML5",
  },
  {
    src: Css,
    name: "CSS3",
  },
  {
    src: JavaScript,
    name: "JavaScript",
  },
  {
    src: ReactLogo,
    name: "React",
  },
  {
    src: Nextjs,
    name: "Nextjs",
  },
  {
    src: Git,
    name: "Git",
  },
  {
    src: Figma,
    name: "Figma",
  },
  {
    src: Sass,
    name: "Sass",
  },
  {
    src: Jira,
    name: "Jira",
  },
];

export const projects = [
  {
    id: 1,
    image: Archis,
    alt: "Archi's website",
    title: "Archi's Academy",
    description:
      "Acrhi's Academy website built in collaboration with the team at at Archi's",
    siteLink: "https://www.archisacademy.com",
  },
  {
    id: 2,
    image: Modern,
    alt: "GPT3 landing page",
    title: "GPT3 Landing page",
    description:
      "GPT3 landing page, modern UI design, built in React and hosted on Netlify.",
    siteLink: "https://gpt3-home.netlify.app",
    githubLink: "https://github.com/vmuya/gpt3-landing",
  },
  {
    id: 3,
    image: Endurance,
    alt: "Endurance home image",
    title: "Endurance Hackathon",
    description:
      "Website for the Endurance Hackathon series hosted by Archi's Academy. This was built in collaborattion with Archi's Academy team members",
    siteLink: "https://endurance.host/",
  },
];
