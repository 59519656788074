import React from 'react';
import HeroImage from '../../assets/hero-square.png';
import CV from '../../assets/cv-2022.pdf';

import './hero.css';

const Hero = () => {
  return (
    <div className="portfolio__hero section__margin">
      <div className="portfolio__hero-container">
        <div className="portfolio__hero_container_text">
          <span className="portfolio__hero-greeting">Hello, I'm</span>
          <h1 className="portfolio__hero-name">Victor Muya</h1>
          <h3 className="portfolio__hero-role">Frontend Developer</h3>

          <h3 className="portfolio__hero-description">
            A curious developer with a passion for building user-centric,
            inclusive experiences on the web.
          </h3>

          <div className="portfolio__hero-buttons">
            <a
              href={CV}
              target="_blank"
              rel="noopener noreferrer"
              className="portfolio__hero-button button--ghost"
            >
              Download CV
            </a>
          </div>
        </div>
        <div className="portfolio__hero-image-container">
          <div className="portfolio__hero-image">
            <img src={HeroImage} alt="victor" className="portfolio__hero-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
