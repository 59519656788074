import React from 'react';

import './contact.css';

const Contact = () => {
  return (
    <div className="portfolio__contact section__margin">
      <div className="portfolio__contact-title">
        <h1>Contact</h1>

        <h3>Get in touch with me</h3>
      </div>

      <div className="portfolio__contact-content">
        <div className="portfolio__contact_content-text">
          <p>
            I'm currently open to freelancing and actively looking for
            internship opportunties, so if you would like to work with me don't
            hesitate to reach out!
          </p>
          <p>
            If you have any questions or would just like to say hi, my inbox is
            always open and I'll get back to you as soon as I can.
          </p>
        </div>

        <div className="portfolio__contact_content-button">
          <a
            href="mailto:victornjenga98@gmail.com"
            className="portfolio__contact_button"
          >
            Get in touch
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
