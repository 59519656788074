import React from "react";
import Project from "./Project";

import { projects } from "../../constants/constants";

import "./work.css";

const Work = () => {
  return (
    <div className="portfolio__work section__margin">
      <div className="portfolio__work-title">
        <h1>Work</h1>

        <h3>Some of the projects in my portfolio</h3>
      </div>

      <div className="portfolio__work_projects">
        {projects.map((proj) => (
          <Project
            imgSrc={proj.image}
            imgAlt={proj.alt}
            title={proj.title}
            description={proj.description}
            siteLink={proj.siteLink}
            githubLink={proj.githubLink}
            key={proj.id}
          />
        ))}
      </div>
    </div>
  );
};

export default Work;
