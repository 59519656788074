import React from 'react';
import PropTypes from 'prop-types';
import { BsGithub } from 'react-icons/bs';

import './project.css';

const Project = ({
  imgSrc,
  imgAlt,
  title,
  description,
  siteLink,
  githubLink,
}) => {
  return (
    <div className="portfolio__project-tab">
      <img src={imgSrc} alt={imgAlt} className="portfolio__project-image" />

      <div className="portfolio__project-text">
        <h3 className="portfolio__project-title">{title}</h3>

        <p className="portfolio__project-description">{description}</p>

        <div className="portfolio__project-links">
          <a href={siteLink} target="_blank" rel="noopener noreferrer">
            See live project
          </a>
          {githubLink ? (
            <a
              href={githubLink}
              target="_blank"
              rel="noopener noreferrer"
              className="portfolio__project-link-github"
            >
              <BsGithub fontSize="20px"
              />
            </a>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Project;

Project.propTypes = {
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  siteLink: PropTypes.string,
  githubLink: PropTypes.string,
};
